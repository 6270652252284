<template>
  <div>
    <el-dialog :visible.sync="sta.show">
      <span slot="title">增加/修改 物料</span>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label><b>分类</b></label><br/>
            <el-cascader v-model="cat.val" :options="cat.ls" placeholder="请选择分类"
                         :props="{label:'NAME',value:'ID',children:'CHILDREN'}"/>
          </div>
          <div class="form-group">
            <label><b>名称</b></label>
            <el-input type="text" v-model="model.NAME"/>
          </div>
          <div class="form-group">
            <label><b>选择仓库</b></label><br/>
            <el-select v-model="model.STORAGE_ID" placeholder="请选择仓库" style="display: block">
              <el-option :label="it.STORAGE_NAME" :value="it.ID" v-for="it in store" :key="it.ID" />
            </el-select>
          </div>
          <div class="form-group">
            <label><b>状态</b></label><br/>
            <el-select v-model="model.STA" placeholder="请选择状态">
              <el-option label="正常" :value="1" />
              <el-option label="禁用" :value="2" />
            </el-select>
          </div>
          <div class="form-group">
            <label><b>单位</b></label>
            <el-input type="text" v-model="model.FUNIT"/>
          </div>
          <div class="form-group">
            <label><b>单价</b></label>
            <div>
              <el-input-number v-model="model.PRICE" :step="0.1" :precision="2" :min="0"></el-input-number>
            </div>
          </div>
          <div class="form-group">
            <label><b>数量</b></label>
            <div>
              <el-input-number :disabled="model.ID!=0" v-model="model.BALANCE" :step="1" :min="0"></el-input-number>
            </div>
          </div>
          <div class="form-group">
            <label><b>中国物品编码(条码)</b></label>
            <el-input type="text" v-model="model.CODE"/>
          </div>
          <div class="form-group">
            <label><b>备注</b></label>
            <el-input type="text" v-model="model.DESCRIPTION"/>
          </div>
          <div class="form-group">
            <label><b>低值通知</b></label>
            <el-input type="text" v-model="model.LOWER_LIMIT"/>
          </div>
          <div class="form-group">
            <label><b>照片上传</b> <span style="color: red">(注：仅支持单张图片上传)</span></label>
            <el-upload accept="image/*" action="" :limit="1" list-type="picture-card" :auto-upload="false"
                       :on-change="onChange"
                       :file-list="fileList" :on-remove="()=>{model.PIC_B64=''}">
              <i class="el-icon-plus"></i>
            </el-upload>
          </div>
        </div>
      </div>
      <div slot="footer" v-loading="sta.loading">
        <el-button icon="el-icon-close" @click="sta.show=false">取消</el-button>
        <el-button icon="el-icon-check" type="success" @click="submit">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      sta: {
        show: false,
        loading: false
      },
      model: {},
      cat: {
        ls: [],
        val: []
      },
      store:[],
      fileList: []
    }
  },
  methods: {
    init(model) {
      this.fileList = []; //初始化，清空上传列表，无它用
      this.sta = {show: true, loading: false}
      this.cat = {ls: this.$parent.cat.ls, val: []};
      this.store = this.$parent.store
      if (model == undefined || model == null) {
        this.model = {
          CVT: 1,
          STA:1,
        };
      } else {
        this.model = JSON.parse((JSON.stringify(model)));
        let val = this.catFind(model.CAT_ID, this.cat.ls);
        if (val == undefined) val = model.CAT_ID + '';
        else val += "," + model.CAT_ID;
        this.cat.val = val.split(',').map(o => {
          return parseInt(o)
        })
        if (this.model.PIC_NAME) this.fileList = [{name: this.model.PIC_NAME, url: this.model.PIC_URL}];
      }
    },
    catFind(id, ls) {
      let n = ls.length;
      for (var i = 0; i < n; i++) {
        let it = ls[i]
        if (it.LEAF) {
          if (it.ID != id) continue;
          return it.ANCESTORS;
        } else {
          var ret = this.catFind(id, it.CHILDREN);
          if (ret != undefined) return ret;
        }
      }
    },
    onChange(file) {
      let self = this;
      this.whale.image.compress({
        file: file.raw,
        callback(cf) {
          self.whale.image.toB64({
            file: cf,
            callback(b64) {
              self.model.PIC_B64 = b64;
            }
          })
        }
      })
    },

    submit() {
      let self = this;
      self.sta.loading = true;
      let n = this.cat.val.length;
      if (n > 0) this.model.CAT_ID = this.cat.val[n - 1];
      let obj = this.store.find(it=>it.ID==this.model.STORAGE_ID)
      if (obj) this.model.STORAGE_NAME = obj.STORAGE_NAME

      this.whale.remote.getResult({
        url: "/api/School/MAT/StockApi/Save",
        data: self.model,
        finally() {
          self.sta.loading = false;
        },
        completed: function () {
          self.sta.show = false;
          self.$emit("on-saved");
        }
      })
    }
  }
}
</script>
